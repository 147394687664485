import React from "react";
import TextContent from "../../TextContent/TextContent";
import WhoWeAreImg from "../../Images/WhoWeAre.png";
import styles from "./WhoWeAre.module.css";
import { eventLabels } from "../../Utils/Data/gtagUtils";
import {AppConfig} from "../../appConfig";

export default function WhoWeAre() {
  window.gtag("event", "conversion", {
    send_to: `${AppConfig.GOOGLE_ADS_ID}/${eventLabels.PAGE_VIEW}`,
    page_name: window.location.pathname,
  });
  return (
    <div className={styles.whoWeAre} id="WhoWeAre">
      <div className={styles.container}>
        <div className={styles.text_cube}>
          <h1>
            <TextContent page="whoWeAre" property="title" />
          </h1>
        </div>

        <div className={styles.img_side}>
          <img className={styles.img} src={WhoWeAreImg} alt="Who we are" />
        </div>

        <div className={`${styles.drorsoft_text} ${styles.textbox_1}`}>
          <TextContent page="whoWeAre" property="cube1" />
        </div>
        <div className={`${styles.drorsoft_text} ${styles.textbox_2}`}>
          <TextContent page="whoWeAre" property="cube2" />
        </div>
      </div>
    </div>
  );
}
