import {AppConfig} from "../appConfig";

const api_url = AppConfig.EMAIL_API_URL;

export const sendEmail = async (data) => {
  console.log(api_url);
  const response = await fetch(api_url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};
