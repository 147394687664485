// gtagUtils.js
import { AppConfig } from "../../appConfig";

export const eventLabels = {
  CLICK_PHONE: "f56MCOa1gYEaEIuNnp4o",
  CLICK_EMAIL: "9v3_CMDj_IAaEIuNnp4o",
  PAGE_VIEW: "KFB_CJLY9YAaEIuNnp4o",
  SUBMIT_FORM: "ebxvCKeciIsDEIuNnp4o",
};

export function gtag_report_conversion({ label }) {
  console.log(window.gtag);
  console.log(label);
  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: `${AppConfig.GOOGLE_ADS_ID}/${label}`,
    });
  }

  return false;
}
