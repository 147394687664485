import React from 'react';
import styles from "./BackButton.module.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {HashLink} from "react-router-hash-link";

function BackButton() {
    return (
        <HashLink to="/#recentProjects" className={styles.backButton}>
            <ArrowBackIcon/>
        </HashLink>
    );
}

export default BackButton;