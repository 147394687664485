import elections_homepage from "../Images/projects/election_homepage.png"
import election from "../Images/projects/election_main.png"
import election1 from "../Images/projects/election1.png"
import election2 from "../Images/projects/election2.png"
import election4 from "../Images/projects/election4.png"
import election5 from "../Images/projects/election5.png"
import election6 from "../Images/projects/election6.png"
import election7 from "../Images/projects/election7.png"
import election8 from "../Images/projects/election8.png"

import keshet_homepage from "../Images/projects/keshet_homepage.png"
import keshet from "../Images/projects/keshet_main.jpeg"
import keshet1 from "../Images/projects/keshet1.jpeg"
import keshet2 from "../Images/projects/keshet2.jpeg"
import keshet3 from "../Images/projects/keshet3.jpeg"
import keshet4 from "../Images/projects/keshet4.jpeg"
import keshet5 from "../Images/projects/keshet5.jpeg"
import keshet6 from "../Images/projects/keshet6.jpeg"
import keshet7 from "../Images/projects/keshet8.jpeg"

import being_homepage from "../Images/projects/being_homepage.png"
import being from "../Images/projects/being_main.jpeg"
import being1 from "../Images/projects/being1.jpeg"
import being2 from "../Images/projects/being2.jpeg"
import being3 from "../Images/projects/being3.jpeg"
import being5 from "../Images/projects/being5.jpeg"
import being6 from "../Images/projects/being6.jpeg"

import rikma_homepage from "../Images/projects/rikma_homepage.png"
import rikma1 from "../Images/projects/rikma1.png"
import rikma3 from "../Images/projects/rikma3.png"
import rikma4 from "../Images/projects/rikma4.png"
import rikma5 from "../Images/projects/rikma5.png"

import league_homepage from "../Images/projects/league_homepage.png"
import league0 from "../Images/projects/league0.png"
import league1 from "../Images/projects/league1.png"
import league2 from "../Images/projects/league2.png"
import league3 from "../Images/projects/league3.png"
import league4 from "../Images/projects/league4.png"
import league5 from "../Images/projects/league5.png"
import league7 from "../Images/projects/league7.png"
import league8 from "../Images/projects/league8.png"

import worker_trivia_homepage from "../Images/projects/worker_trivia_homepage.png"
import worker_trivia from "../Images/projects/worker_trivia_main.png"
import worker_trivia1 from "../Images/projects/worker_trivia_Page_1.png"
import worker_trivia2 from "../Images/projects/worker_trivia_Page_2.png"
import worker_trivia3 from "../Images/projects/worker_trivia_Page_3.png"
import worker_trivia4 from "../Images/projects/worker_trivia_Page_4.png"
import worker_trivia5 from "../Images/projects/worker_trivia_Page_5.png"
import worker_trivia6 from "../Images/projects/worker_trivia_Page_6.png"
import worker_trivia7 from "../Images/projects/worker_trivia_Page_7.png"

import contrust_homepage from "../Images/projects/contrust_homepage.png"
import contrust_1 from "../Images/projects/contrust1.png"
import contrust_2 from "../Images/projects/contrust2.png"
import contrust_3 from "../Images/projects/contrust3.png"
import contrust_4 from "../Images/projects/contrust4.png"
import contrust_5 from "../Images/projects/contrust5.png"
import contrust_6 from "../Images/projects/contrust6.png"
import contrust_7 from "../Images/projects/contrust7.png"
import contrust_8 from "../Images/projects/contrust8.png"
import contrust_mobile_1 from "../Images/projects/contrust_mobile_1.png"
import contrust_mobile_2 from "../Images/projects/contrust_mobile_2.png"


const PROJECTS = {
    contrast_and_uncontrust: {
        "title": {
            "he": "",
            "en": "Visualized Research Data Web App"
        },
        "content": {
            "he": "",
            "en": "ContrastDB and UncontrustDB - Two open source projects for beautifully visualized complex experiments data"
        },
        customers: {
            he: "",
            en: "Professor Liad Mudrik lab - a Tel Aviv University lab that investigates high-level cognition, both at the behavioral and neural level."
        },
        need: {
            he: "",
            en: "Liad Mudrik lab believes in open-science, hence their need to make their database for studies of unconscious and conscious processing accessible to the research community and the general public, with tools to analyze and visualize the data.\n" +
                "They needed fast processing of different experiments and their connections to each other. Allowing self exploration, and for researches self submitting of new studies which used to be manual and with a complex back and force process",
        },
        solution: {
            he: "",
            en: "We have built two open-source web applications that aggregate data from about 1000 experiments, with fast search, filtering, aggregation, and visualization of combined data from the experiments. \n"
        },
        homepage_image: contrust_homepage,
        other_images: [contrust_1, contrust_2, contrust_3, contrust_4, contrust_5, contrust_6, contrust_7, contrust_8],
        mobile_images: [contrust_mobile_1, contrust_mobile_2],
        live_link: "https://contrastdb.tau.ac.il/"
    },
    elections: {
        "title": {
            "he": "מערכת ניהול קלפיות בחירות",
            "en": "Election day management and reporting system\n"
        },
        "content": {
            "he": "הקמנו מערכת ניהול, במיקוד על שיבוץ קלפיות וניהול יום הבחירות למפלגה מרכזית",
            "en": "Staff and volunteer scheduling, placement and tracking on election day "
        },
        customers: {
            he: "מפלגה מרכזית בישראל, נדרשה להקים בלוז קצר מערכת ניהול ושיבוץ השיבוץ של חברי הועדות והמשקיפים ליום הבחירות. ולייצר לאלו כלי טכנולוגי לדיווח התוצאות ",
            en: "A major Israeli political party\n"
        },
        need: {
            he: "ניהול מספר רב של מתנדבים ו/או שכירים אשר משקיפים על הקלפיות, בהפעלה שלו, איסוף מידע ותקלות ממנו בצורה מהירה, נוחה ואיכותית.\n" +
                "יש צורך ביידוע מהיר, איכותי ומיידי על דיווח ספירת תוצאות קלפי בבחירות\n",
            en: "Quick and effective management of party workers and volunteers during massive election day campaign operation",
        },
        solution: {
            he: "מערכת ארגונית לניהול ושיבוץ פיקוח קלפיות ביום הבחירות\n" +
                "ניהול אלפי פעילים ושיוך לאלפי קלפיות\n" +
                "צד קצה למטות לגיוס ממלאי תפקידים ביום הבחירות\n" +
                "צד קצה למשקיפים \n" +
                "דיווח תוצאות כולל צילום דף משקיף\n" +
                "דיווח כניסה ויציאה מהקלפי לאלפי פעילים כולל \n" +
                "צד מטה מרכזי לדיווח בזמן אמת על מצב הקלפיות\n",
            en: "Drorsoft delivered a custom system, on a very tight timetable, to schedule, place, manage and track thousands of workers and volunteers throughout Israel on election day. While other parties’ systems crashed due to heavy usage on election day, our system, built using best of brand cloud technology, was operational and fulfilled its purpose easily throughout the day. It was also used during the party’s internal review of its election day performance, and to calculate pay for election day workers."
        },
        homepage_image: elections_homepage,
        other_images: [election, election1, election2],
        mobile_images: [election4, election5, election6, election7, election8],
        live_link: null
    },
    keshst: {
        "title": {
            "he": "קשת - אפליקציית רשת לניהול השקעות ותרומות\n",
            "en": "Keshet - web app for managing donor-advised fund’s philanthropic activities "
        },
        "content": {
            "he": "אפליקציית רשת לניהול השקעות ותרומות של פילנטרופים",
            "en": "Helping the fund and its donors easily and effectively manage their accounts, projects and contributions"
        },
        customers: {
            he: "עמותה המרכזת את פעילות התרומות של פילנטרופים יהודים מחו\"ל ומאפשרת להם כלים למיקוד ולניהול התרומה שלהם \n",
            en: "Keshet - a donor-advised fund matching Jewish philanthropists to projects and organizations requiring funding, and managing their donations"
        },
        need: null,
        solution: {
            he: "דרך האפליקציה הם יכולים להתעדכן במצב חשבונם בעמותה, סטטוס בקשתם לתרומה לעמותה כזו או אחרת ועוד.\n",
            en: "Drorsoft developed a bilingual mobile-friendly web app which helps donors easily manage their account at the donor-advised fund, including selecting projects to support, seeing details and reports of their donations, etc. "
        },
        homepage_image: keshet_homepage,
        other_images: [keshet, keshet7, keshet6],
        mobile_images: [keshet1, keshet2, keshet3, keshet4, keshet5],
        live_link: null
    },
    being: {
        title: {
            he: "Being - מערכת שליטה בנוכחות למערכות חינוך",
            en: "Being - An attendance management system for schools and educational organizations\n"
        },
        content: {
            he: "מערכת בדיקת נוכחות והגעה לפעילות, עם שליפת נתונים וסטטיסטיקה איכותית, וממשק רספונסיבי לשימוש בסלולרי ובמחשב האישי\n",
            en: "A mobile-friendly web app allowing teachers to check and report attendance and providing analysis and high quality statistics to the school or organization.\n"
        },
        customers: {
            he: "בתי ספר ומערכות חינוך, חוגים,  מתנ\"סים, אנשי חינוך והדרכה, תנועות נוער ופעילות בלתי פורמלית.\n",
            en: "Schools and educational organizations, community centers, educators and teachers.\n"
        },
        need: {
            he: "דוחות נוכחות לצרכים פנימיים, דיווח למערכות חיצוניות ולזיהוי דפוסי נוכחות לקבוצות, כיתות  וליחידות ויחידים.\n" +
                "רוב המערכות הקיימות מסורבלות, עם ממשק משתמש לא מותאם לנייד, ולראייה ארגונים רבים עדיין מבזבזים זמן רב בניסיון לנהל נוכחות ידנית.\n",
            en: "Attendance reports for internal needs or reporting externally; and identifying attendance patterns for groups, classes and individuals. Currently most available systems are cumbersome, not mobile friendly and many organizations still spend too much of their  time trying to manage attendance manually.\n",
        },
        solution: {
            he: "מערכת לניהול נוכחות בקבוצות בארגון. \n" +
                "כמה סוגי הרשאות-יוזרים, בדגש על פילוח מידע לכל הרשאה. כל אחד שולט ומנהל את החניכים.\n" +
                "יכולת פילוח של המשתתפים לכמה קבוצות\n" +
                "הגדרה דינאמית של סוגי המפגשים\n" +
                "דפי ניתוח נתונים \n",
            en: "A mobile-friendly web app for managing attendance for groups and classes at your school or  organization. With advanced permissions control, support for multiple groups/classes, personalized and dynamic configuration, and extensive reports and analysis, Being is sure to exceed your expectations."
        },
        homepage_image: being_homepage,
        other_images: [being2, being5],
        mobile_images: [being, being1, being3, being6],
        live_link: null
    },
    league: {
        "title": {
            "he": "ליגת טורנירי כדורגל",
            "en": "Hachalutz - youth sports tournaments management web app\n"
        },
        "content": {
            "he": "אפליקציה לשימוש בני נוער ומדריכי נוער בליגות הכדורגל בקיץ של ארגון החלוץ. לארגון הקבוצות, ניהול המחזורים ולצפייה בתוצאות",
            "en": "A mobile web app managing teen sport leagues and tournaments. \n"
        },
        customers: {
            he: "החלוץ – חינוך דרך ספורט, הוא ארגון ספורט חינוכי המדריך מאות קבוצות ברחבי הארץ. בארגון עשרות מאמנים ומאות שחקנים. במהלך השנה החלוץ מקיים חוגים, כאשר בחופשים מארגן עם קבוצותיו ועם הרשויות השונות טורנירי כדורגל וליגות. ",
            en: "Hachalutz is an educational sports organization, with hundreds of teams throughout Israel. \n"
        },
        need: {
            he: "החלוץ מקיים ליגות כדורגל במהלך החופשות בדגש בחופש הגדול. בכל ליגה מספר קבוצות ומאות משתתפים.  \n" +
                "ארגון של ליגה דורש המון תקשורת והעברת מידע זמין למשתתפים מהמארגנים (מתי כל משחק, מי נגד מי, טבלת הליגה), ומכל משחק אל רכז הליגה (דיווח תוצאה, מבקיעים ומבשלים, כרטיסים צהובים-אדומים-וירוקים\n",
            en: "Prior to the development of this app, Hachalutz  was tediously tracking its multiple leagues, tournaments, dozens of teams and thousands of players manually, and communicating with players in a haphazard way. \n"
        },
        solution: {
            he: "דרורסופט פיתחה עבור החלוץ אפליקציה ייעודית לשחקנים ולמפעילי הליגות המאפשרת הזמנת שחקנים,ניהול קבוצות, מחזורים ומשחקים וצפייה בנתונים וסטטיסטיקה לכל ליגה\n" +
                "המערכת כולל בין השאר אפשרות לניהול ליגות מרובות\n",
            en: "Drorsoft has developed for Hachalutz a dedicated mobile web app for players, team coaches and managers. The app includes online management and registration for teams and tournaments, reporting game results and stats, and displaying results and stats to players. "
        },
        homepage_image: league_homepage,
        other_images: [],
        mobile_images: [league0, league1, league2, league3, league4, league5, league7, league8],
        live_link: null
    },
    worker_trivia: {
        "title": {
            "he": "משחק טריוויה ארגוני",
            "en": "Workplace trivia game\n"
        },
        "content": {
            "he": "יחד עם האגף להתאגדות עובדים בהסתדרות פיתחנו אפליקציה לשימוש בימי ועדים הכוללת שאלון מוגבל בזמן וניקוד",
            "en": "An educational and bonding experience"
        },
        customers: {
            he: "ההסתדרות הכללית החדשה מציינת 100 שנה להיווסדה. בהסתדרות האגף להתאגדות עובדים אשר מלווה ותומך בועדי העובדים במקומות העבודה. בהסתדרות מאות אלפי חברים, אשר מידת מעורבותם והכרותם עם הארגון משתנה. ועדי העובדים מקיימים פעילויות חברתיות והוואי חברתי במקביל לתמיכה המקצועית בזכויות העובדים מול המעסיק.",
            en: "The Histadrut, Israel's leading union \n"
        },
        need: {
            he: "ציון חגיגי ופיתוח המודעות של חברי ההסתדרות לציון 100 שנה להסתדרות. תוך חיזוק החיבור להסתדרות ולועד בו הם חברים.",
            en: "A fun way to celebrate the 100th anniversary of the founding of the Histadrut, while increasing union members’ awareness and identification with the organization.",
        },
        solution: {
            he: "אפליקצית \"טריוויה ארגונית\"\n" +
                "כל ועד יכול להקים לעצמו משחק, בו המסך יהיה עם המיתוג של הועד\n" +
                "במערכת מאות שאלות כלליות על ההסתדרות בדרגות קשוי שונות ועם יכולת הזנת שאלות (ותשובות) על מקום העבודה והועד הספציפי – קסטום חווית המשתמש\n" +
                "ערבוב המשחק ב\"כל אחד זוכה\" ובנוסף תחרות נושאת פרסים לאור ניקוד במשחק.\n",
            en: "We developed a mobile web app in which each workers’ committee could create their own branded trivia game using pre-written suggested questions about the Histradrut, with the option to add custom questions. At the end of the game, everyone wins, and the players with the highest scores win prizes!"
        },
        homepage_image: worker_trivia_homepage,
        other_images: [],
        mobile_images: [worker_trivia, worker_trivia1, worker_trivia2, worker_trivia3, worker_trivia4, worker_trivia5, worker_trivia6, worker_trivia7],
        live_link: null
    },
    rikma: {
        title: {
            "he": "רקמה אנושית – יד זיכרון לנפטרי הקורונה במדינת ישראל",
            "en": "Memorial website for victims of the COVID-19 pandemic in Israel \n"
        },
        content: {
            "he": "אתר להנצחת הנפטרים ממגפת הקורונה בישראל, עם יכולת הזנה וחיפוש של נספים על ידי באי האתר\n",
            "en": "Helping memorialize the thousands of tragic casualties of the pandemic, where visitors can search, learn and report about victims\n"
        },
        customers: {
            he: "תנועת דרור ישראל ואזרחי מדינת ישראל \n",
            en: "Dror Israel and the citizens of Israel"
        },
        need: {
            he: "מאז פרצה המגיפה מספר המתים הולך וגדל ובשיח הציבורי רבים מהם נשארים בגדר מספר. אנשים ללא שם או שם משפחה ללא כתובת וללא זיכרון ראוי למי שהיו.\n" +
                "לא כל אדם שנפטר מצליח להגיע אל התודעה והשיחה הציבורי, ודבר זה יוצר תחושה אלמונית וריחוק מהנפטרים והאבל הלאומי.\n" +
                "אין נגישות ומקומות לציון המתים, ע\"י המשפחות, החברים ומקומות העבודה.\n",
            en: "Since the pandemic began, over 8,000 have died in Israel from COVID-19. The vast majority are anonymous - even their names are rarely mentioned in the press. They deserve to be remembered, in the same way the country commemorates victims of war and terrorism. "
        },
        solution: {
            he: "הקמת יד זיכרון דיגיטלית לנפטרי מגיפת הקורונה בישראל. באתר תוכלו למצוא את שמות הנפטרים, פרטיהם וסיפוריהם כפי שהצלחנו ללקט מהעיתונות וממקורות נוספים. יד הזיכרון לנפטרי הקורונה במדינת ישראל הוקמה מתוך תחושה עזה שפרויקט כזה הינו חיוני לנו כחברה ולמשפחות הנפטרים. הזמנו את הציבור הרחב דרך האתר להכיר את הנפטרים, את שם וסיפורם, ובכך להגיע לכל אותם קרובי משפחה, אחים, אחיות, שכנים, קולגות, והסיפורים שמאחוריהם - אותם נדאג להנציח באתר.\n" +
                "באתר השתמשו כלי תקשורת ארציים בצורה דחופה (ynet, ערוץ12?13?11?) בישראל והוא עודד כתבות ואייטמים על נפטרים, עם משפחותיהם – ובכך טרם לכך שהאמירה \"לכל איש יש שם\" תוגשם.\n",
            en: "The website serves as a digital memorial for victims of the COVID-19 pandemic in Israel. Visitors can search, read names and short descriptions of the victims, and add information about their loved ones. It  It is the most comprehensive compilation of personal names and stories of pandemic victims in Israel and has been used by national media  to facilitate reporting on victims' stories."
        },
        homepage_image: rikma_homepage,
        other_images: [rikma1, rikma3],
        mobile_images: [rikma4, rikma5],
        live_link: null
    },
}

export default PROJECTS
