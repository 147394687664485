import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Working with GTM
import TagManager from "react-gtm-module";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {AppConfig} from "./appConfig";

if (AppConfig.RUNTIME_ENVIRONMENT === "production" || "staging") {
  const tagManagerArgs = {
    gtmId: "GTM-TS4TD5C",
  };
  TagManager.initialize(tagManagerArgs);
  console.log("GTM is connected...");
}
// End GTM Code

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={AppConfig.CAPTCHA_SITE_KEY}>
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
