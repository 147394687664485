import React from "react";
import { landingPages } from "./data";
import { useParams, useHistory } from "react-router-dom";
import styles from "./Landing.module.css";
import DrorsoftSmallLogo from "./dlogo";
import { ReactComponent as QuotesSvg } from "../Images/quotes.svg";
import { ReactComponent as BottomLines } from "../Images/bottom-lines.svg";
import ImageGallery from "react-image-gallery";
import LandingContactBox from "./LandingContactBox";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/core";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { eventLabels } from "../Utils/Data/gtagUtils";
import {AppConfig} from "../appConfig";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default function LandingPage() {
  const { tech_name } = useParams();
  const history = useHistory();
  window.gtag("event", "conversion", {
    send_to: `${AppConfig.GOOGLE_ADS_ID}/${eventLabels.PAGE_VIEW}`,
    page_name: window.location.pathname,
  });

  const data = landingPages.find((tech) => tech.techName === tech_name);
  if (data) {
    console.log(data);
  } else {
    history.push("/");
    return (
      <div>
        Redirecting to <a href="https://www.drorsoft.com">home</a>
      </div>
    );
  }
  const imgsDesktop = [
    ...data?.imgsDesktop.map((image) => {
      return {
        original: image,
      };
    }),
  ];
  const imgsMobile = [
    ...data?.imgsMobile.map((image) => {
      return {
        original: image,
      };
    }),
  ];
  const quotes = [
    ...data?.quotes.map((quote) => {
      return (
        <SwiperSlide className={styles.swiperSlide}>
          {quote.text} <div className={styles.quoteName}>{quote.name}</div>
        </SwiperSlide>
      );
    }),
  ];
  return (
    <div id="Tech" className={styles.container}>
      {/* <BackButton /> */}
      <DrorsoftSmallLogo />

      {/* <div style={{ width: 100 }}></div> */}

      <div className={styles.text_cube}>{data.title}</div>

      <div className={styles.contDesc}> {data.description}</div>

      {quotes.length > 0 && (
        <div className={styles.swiperCont}>
          <QuotesSvg className={styles.quoteSvg} />
          <Swiper
            style={{
              "--swiper-pagination-color": "#05447c",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "28px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
            onSwiper={(swiper) => (window.swiper = swiper)}
            slidesPerView={1}
            spaceBetween={0}
            // navigation
            loop
            // scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            className={styles.swiper}>
            {quotes}
          </Swiper>
        </div>
      )}

      {imgsDesktop.length > 0 && (
        <div className={styles.imgGalleryDesktop}>
          <ImageGallery
            items={imgsDesktop}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            showNav={true}
          />
        </div>
      )}
      {imgsMobile.length > 0 && (
        <div className={styles.imgGalleryMobile}>
          <ImageGallery
            items={imgsMobile}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            showNav={false}
          />
        </div>
      )}

      <div className={styles.contact_cube}>
        <LandingContactBox techName={tech_name} />
      </div>
      <div className={styles.bottomLines}>
        <BottomLines className={styles.bottomLinesSvg} />
      </div>
    </div>
  );
}
