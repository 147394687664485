import React from "react";
import styles from "./Project.module.css";
import TextContent from "../../TextContent/TextContent";
import { useParams } from "react-router-dom";
import useProjects from "../../hooks/useProjects";
import ImageGallery from "react-image-gallery";
import clsx from "clsx";
import { eventLabels } from "../../Utils/Data/gtagUtils";
import {AppConfig} from "../../appConfig";

function Project() {
  const { project_name } = useParams();
  const project = useProjects(project_name);

  window.gtag("event", "conversion", {
    send_to: `${AppConfig.GOOGLE_ADS_ID}/${eventLabels.PAGE_VIEW}`,
    page_name: window.location.pathname,
  });
  const desktopImages = [
    ...project.other_images.map((image) => {
      return {
        original: image,
      };
    }),
    {
      original: project.homepage_image,
    },
  ];
  const mobileImages = [
    ...project.mobile_images.map((image) => {
      return {
        original: image,
      };
    }),
  ];

  if (project) {
    return (
      <div className={styles.container}>
        <section className={styles.info__heading}>
          <h1>
            <TextContent
              page="recentProjects"
              project_name={project_name}
              property="title"
            />
          </h1>
          <p>
            <TextContent
              page="recentProjects"
              project_name={project_name}
              property="content"
            />
          </p>
          {project.live_link && (
          <section className={styles.project_link}>
            <a
              href={project.live_link}
              target="_blank"
              rel="noreferrer"
              className="button">
              <TextContent page="recentProjects" property="buttonTitle" />
            </a>
          </section>
        )}
        </section>
        {project.customers && (
          <section className={styles.info__customer}>
            <h3>
              <TextContent page="recentProjects" property="customersTitle" />
            </h3>
            <p>
              <TextContent
                page="recentProjects"
                project_name={project_name}
                property="customers"
              />
            </p>
          </section>
        )}
        {project.need && (
          <section className={styles.info__need}>
            <h3>
              <TextContent page="recentProjects" property="needTitle" />
            </h3>
            <p>
              <TextContent
                page="recentProjects"
                project_name={project_name}
                property="need"
              />
            </p>
          </section>
        )}
        {project.solution && (
          <section className={styles.info__solution}>
            <h3>
              <TextContent page="recentProjects" property="solutionTitle" />
            </h3>
            <p>
              <TextContent
                page="recentProjects"
                project_name={project_name}
                property="solution"
              />
            </p>
          </section>
        )}

        <div
          className={clsx(
            styles.mobile_images,
            styles.images,
            styles.small_gallery
          )}>
          <div className={styles.image_top} />
          <div className={styles.image_gallery_container}>
            <ImageGallery
              items={mobileImages}
              showFullscreenButton={false}
              showPlayButton={true}
              autoPlay={true}
              showBullets={true}
            />
          </div>
        </div>

        <div className={clsx(styles.images, styles.big_gallery)}>
          <div className={styles.image_top} />
          <div className={styles.image_gallery_container}>
            <ImageGallery
              items={desktopImages}
              showFullscreenButton={false}
              showPlayButton={true}
              autoPlay={true}
              showBullets={true}
              bulletClass={styles.images_gallery_bullet}
              bulletActiveClass={styles.images_gallery_bullet_active}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <h1>Project Not Found</h1>
      </div>
    );
  }
}

export default Project;
