import { Field, Form, Formik } from "formik";
import styles from "./Contact.module.css";
import ContactImg from "../../Images/ContactUs.jpg";
import TextContent from "../../TextContent/TextContent";
import { sendEmail } from "../../services/email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { whatWeOffer } from "../../Content/content.json";
import { useSnapshot } from "valtio";
import selectedOption from "../../snapshots/selectedOption";
import LinesWithCircles from "../../Partials/LinesWithCircles";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  eventLabels,
  gtag_report_conversion,
} from "../../Utils/Data/gtagUtils";
import { AppConfig } from "../../appConfig";

export default function Contact() {
  const snap = useSnapshot(selectedOption);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const queryParams = new URLSearchParams(window.location.search);
  const utmParams = Object.fromEntries(queryParams);

  window.gtag("event", "conversion", {
    send_to: `${AppConfig.GOOGLE_ADS_ID}/${eventLabels.PAGE_VIEW}`,
    page_name: window.location.pathname,
  });
  const showToast = (isDelivered) => {
    isDelivered
      ? toast.success("Got it, Thanks!")
      : toast.error(
          `Something went wrong, try calling us instead? ${AppConfig.CONTACT_PHONE}`,
          {
            id: "error",
            duration: 5000,
          }
        );
  };

  const emailUs = ({
    name,
    email,
    phone,
    whatWeOffer,
    message,
    captchaToken,
  }) => {
    const data = {
      token: captchaToken,
      subject: `New message from Drorsoft Contact Form: ${email}`,
      email: email || "",
      utm: utmParams,
      name: name || "",
      whatWeOffer: whatWeOffer || "",
      plain: `
                Name: ${name} \n
                Email: ${email} \n
                Phone: ${phone} \n
                what we offer: ${whatWeOffer} \n
                Message: ${message} \n
                
                ---
                This message was sent from the Drorsoft website contact form
            `,
    };
    return sendEmail(data);
  };

  const handleSubmit = async (
    { name, email, phone, whatWeOffer, message },
    { resetForm }
  ) => {
    const captchaToken = await executeRecaptcha("contact_form_submit");
    const payload = { name, email, phone, whatWeOffer, message, captchaToken };
    try {
      const response = await emailUs(payload);
      const isMessageDelivered = response.hasOwnProperty("MessageId");
      showToast(isMessageDelivered);
      isMessageDelivered && resetForm();
    } catch (error) {
      showToast(false);
    }
  };

  const language = localStorage.getItem("language") || "error";
  const whatWeOfferOptions = [];
  Object.keys(whatWeOffer).forEach((item) => {
    if (item !== "title") {
      whatWeOfferOptions.push({
        name: item,
        text: whatWeOffer[item]["title"][language],
      });
    }
  });
  whatWeOfferOptions.push({
    name: "other",
    text: "Something else",
  });

  return (
    <div id="Contact" className={styles.Contact}>
      <div className={styles.light_blue}></div>
      <div className={styles.img__container}>
        <LinesWithCircles
          className={styles.contact__lines}
          line_modifier={200}
          rotationDegree={180}
        />
        <img className={styles.img} src={ContactImg} alt="Talk to Us" />
      </div>

      <div className={styles.container}>
        <div className={styles.heading_side}>
          <div className={styles.header}>
            <h1>
              <TextContent page="contact" property="title" />
            </h1>
          </div>

          <ul className={styles.footer__list}>
            <li className={styles.footer__list__item}>CBHC Computing</li>
            <li className={styles.footer__list__item}>BN Number 515241479</li>
            <li className={styles.footer__list__item}>
              <a
                href="https://goo.gl/maps/fXWiTehdJFzsmmWv6"
                target="_blank"
                rel="noreferrer">
                Hagana Road 34, Tel Aviv-Yafo 6772137
              </a>
            </li>
            <li className={styles.footer__list__item}>
              <a
                href={`mailto:${AppConfig.CONTACT_EMAIL}`}
                onClick={() =>
                  gtag_report_conversion({ label: eventLabels.CLICK_EMAIL })
                }>
                {AppConfig.CONTACT_EMAIL}
              </a>
            </li>
            <li className={styles.footer__list__item}>
              <a
                href={`tel:${AppConfig.CONTACT_PHONE}`}
                onClick={() =>
                  gtag_report_conversion({ label: eventLabels.CLICK_PHONE })
                }>
                {AppConfig.CONTACT_PHONE}
              </a>
            </li>
          </ul>
          <ul className={styles.social__list}>
            <li className={styles.footer__list__item}>
              <a
                href="https://www.facebook.com/drorsoft"
                target="_blank"
                rel="noopener noreferrer">
                {" "}
                Facebook{" "}
              </a>
            </li>
            <li className={styles.footer__list__item}>
              <a
                href="https://twitter.com/drorsoft"
                target="_blank"
                rel="noopener noreferrer">
                Twitter{" "}
              </a>
            </li>
            <li className={styles.footer__list__item}>
              <a
                href="https://linkedin.com/company/drorsoft"
                target="_blank"
                rel="noopener noreferrer">
                {" "}
                Linkedin{" "}
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.form_side}>
          <h4 className={styles.sentence}>
            Coding your dreams is only
            <br />a click away
          </h4>
          <section>
            <div className={styles.contact_form}>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  whatWeOffer: snap.option ? snap.option : "",
                  message: "",
                }}
                enableReinitialize={true}>
                {({ isSubmitting }) => (
                  <Form className={styles.form}>
                    <div className={styles.form_fields}>
                      <Field
                        type="text"
                        name="name"
                        required
                        placeholder="Name and Organization"
                      />
                      <Field
                        type="email"
                        name="email"
                        required
                        placeholder="Email"
                      />
                      <Field type="tel" name="phone" placeholder="Phone" />
                      <Field as="select" name="whatWeOffer" required>
                        <option value="" disabled defaultValue>
                          How can we help?
                        </option>
                        {whatWeOfferOptions.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <Field as="textarea" name="message" placeholder="Message" />
                    <button
                      onClick={() =>
                        gtag_report_conversion({
                          label: eventLabels.SUBMIT_FORM,
                        })
                      }
                      type="submit"
                      disabled={isSubmitting}
                      className={styles.form__submit_btn}>
                      {!isSubmitting ? (
                        "SEND"
                      ) : (
                        <FontAwesomeIcon icon={faCog} className="fa-spin" />
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
