import React from "react";
import { Field, Form, Formik } from "formik";
import styles from "./LandingContactBox.module.css";
import { sendEmail } from "../services/email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import {AppConfig} from "../appConfig";
// import { useSnapshot } from "valtio";
// import selectedOption from "../snapshots/selectedOption";

export default function LandingContactBox(props) {
  // const snap = useSnapshot(selectedOption);
  const techName = props.techName;
  const showToast = (isDelivered) => {
    isDelivered
      ? toast.success("Got it, Thanks!")
      : toast.error(
          `Something went wrong, try calling us instead? ${AppConfig.CONTACT_PHONE}`
        );
  };

  const emailUs = ({ name, email, phone, whatWeOffer, message }) => {
    const data = {
      subject: `New message from Drorsoft Contact Form: ${email}`,
      email: email || "",
      name: name || "",
      whatWeOffer: whatWeOffer || "",
      plain: `
                Name: ${name} \n
                Email: ${email} \n
                Phone: ${phone} \n
                what we offer: ${whatWeOffer} \n
                Message: ${message} \n
                
                ---
                This message was sent from the Drorsoft website contact form
            `,
    };
    // console.log(data);
    return sendEmail(data);
  };

  const handleSubmit = async (
    { name, email, phone, whatWeOffer, message },
    { resetForm }
  ) => {
    const payload = { name, email, phone, whatWeOffer, message };
    const response = await emailUs(payload);
    const isMessageDelivered = response.hasOwnProperty("MessageId");
    showToast(isMessageDelivered);
    isMessageDelivered && resetForm();
  };

  //   const language = localStorage.getItem("language") || "error";
  //   const whatWeOfferOptions = [];
  //   Object.keys(whatWeOffer).forEach((item) => {
  //     if (item !== "title") {
  //       whatWeOfferOptions.push({
  //         name: item,
  //         text: whatWeOffer[item]["title"][language],
  //       });
  //     }
  //   });
  //   whatWeOfferOptions.push({
  //     name: "other",
  //     text: "Something else",
  //   });

  return (
    <div id="Contact" className={styles.Contact}>
      <div className={styles.container}>
        <div className={styles.form_side}>
          <h4 className={styles.sentence}>
            Coding your dreams is only a click away {techName}
          </h4>
          <section>
            <div className={styles.contact_form}>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  whatWeOffer: techName,
                  message: "",
                }}
                enableReinitialize={true}
              >
                {({ isSubmitting }) => (
                  <Form className={styles.form}>
                    <div className={styles.form_fields}>
                      <Field
                        type="text"
                        name="name"
                        required
                        placeholder="Name and Organization"
                      />
                      <Field
                        type="email"
                        name="email"
                        required
                        placeholder="Email"
                      />
                      <Field type="tel" name="phone" placeholder="Phone" />
                      {/* <Field as="select" name="whatWeOffer" required>
                        <option value="" disabled defaultValue>
                          How can we help?
                        </option>
                        {whatWeOfferOptions.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.text}
                          </option>
                        ))}
                      </Field> */}
                    </div>
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      rows="3"
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={styles.form__submit_btn}
                    >
                      {!isSubmitting ? (
                        "SEND"
                      ) : (
                        <FontAwesomeIcon icon={faCog} className="fa-spin" />
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
